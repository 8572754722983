import * as idb from "idb-keyval/dist/idb-keyval-cjs-compat.min";
const postIdStore = new idb.Store("viewed-posts-db", "post-ids");

export async function setPostId(postId) {
    const postTimeout = await idb.get(postId, postIdStore);

    if (postTimeout && postTimeout > new Date()) {
        return;
    }

    setView(postId);
    await idb.set(postId, nextWeek(), postIdStore);
}

function setView(postId) {
    $.ajax({
        url: popularPosts.ajax_url,
        type: "post",
        data: {
            action: "set_view",
            postId,
            nonce: popularPosts.ajax_nonce,
        },
    });
}

function nextWeek() {
    const today = new Date();
    const nextWeek = today.setDate(today.getDate() + 7);

    return nextWeek;
}
